@import "variables";

.no-results-message {
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  &.horizontal {
    flex-flow: row-reverse nowrap;

    .icon {
      width: 40px;
      height: 40px;
      margin-top: 0;
      margin-right: 15px;
    }

    .main {
      font-size: 1.4rem;
      text-align: left;
      margin-bottom: 0;
    }

    .secondary {
      font-size: 1.4rem;
    }
  }

  .icon {
    width: 120px;
    height: 120px;
    fill: mat-color($app-foreground, icon);

    &:not(.mat-icon) {
      margin-bottom: 25px;
    }
  }

  .main {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 5px;
  }

  .secondary {
    font-size: 1.6rem;
    text-align: center;
    max-width: 450px;
    color: mat-color($app-foreground, secondary-text);
  }
}