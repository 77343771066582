@import "variables";

.multiline-mat-menu-item.mat-menu-item {
  display: block;
  white-space: normal;
  line-height: normal;
  height: auto;
  min-height: 48px;
  padding: 10px 16px;
  font-size: 1.4rem;

  .title {
    font-size: 1.5rem;
  }

  .description {
    color: mat-color($app-foreground, secondary-text);
    margin-top: 3px;
  }

  &.active .description {
    color: inherit;
  }
}

.mat-menu-item.active {
  color: mat-color($app-primary, default);

  .title {
    font-weight: 500;
  }
}