@import "variables";

$pagination-height: 56px;
$table-header-height: 85px;

.responsive-material-table {
  @media screen and (max-width: $medium) {
    padding: 0;
    background-color: initial;
    border: none;
    box-shadow: none;
    margin: 0;

    .mat-table {
      background-color: initial;
      padding: 0;
    }

    .mat-paginator {
      background-color: transparent;
      margin-top: 15px;
    }

    .mat-paginator-page-size {
      display: none;
    }

    .mat-header-row {
      display: none;
    }

    .header-inner {
      padding-left: 0;
      padding-right: 0;
      display: block;
      height: auto;

      .left {
        margin-bottom: 15px;
      }

      .right {
        justify-content: flex-start;
      }
    }


    .mat-row {
      display: block;
      overflow: hidden;
      height: auto;
      min-height: 0;
      margin: 0 0 15px;
      padding: 0 15px;
      border-radius: 4px;
      @include mat-elevation(1);
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .mat-row::after {
      display: none;
    }

    .mat-cell {
      margin: 16px 0;
      min-height: 0;

      &:first-of-type {
        padding-left: 0;
      }
    }

    [data-label] {
      &:before {
        content: attr(data-label);
        display: inline;
        font-weight: normal;
        padding-right: 5px;
        color: mat-color($app-foreground, secondary-text);
      }
    }

    .hidden-on-mobile, .mat-column-select {
      display: none;
    }

    .mat-column-edit {
      position: absolute;
      bottom: 8px;
      right: 5px;
      margin: 0;
      padding: 0;
    }

    .filter-chip-list {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}